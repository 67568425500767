import _ from "lodash"
import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "components/Layout"
import Content from "components/Content"

const Container = styled.div``

const MembersPage = ({ data }) => {
  const {
    allMembersJson: { edges },
  } = data

  const grouped = {}

  edges.forEach(edge => {
    const { node } = edge

    if (!grouped[node.Role]) {
      grouped[node.Role] = []
    }
    grouped[node.Role].push(node)
  })

  return (
    <Layout>
      <Helmet>
        <title>Legacy of Steel - Members</title>
      </Helmet>
      <Content>
        <Container>
          <h1>Members</h1>
          {_.map(_.values(grouped), nodes => (
            <div>
              <h3>{nodes[0].Role}</h3>
              <ul>
                {_.map(nodes, node => (
                  <li key={node.Id}>{node.Name}</li>
                ))}
              </ul>
            </div>
          ))}
        </Container>
      </Content>
    </Layout>
  )
}
export const query = graphql`
  query MembersPageQuery {
    allMembersJson(sort: { fields: [Role, Name], order: ASC }) {
      edges {
        node {
          Id
          Name
          Role
        }
      }
    }
  }
`
/*
query MyQuery {
  allMembersJson(sort: {fields: [Role, Name], order: ASC}) {
    group(field: Role) {
      edges {
        node {
          id
          Name
          Role
        }
      }
    }
  }
}
*/
export default MembersPage
